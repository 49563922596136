import React from 'react';
import Loader from '../components/Loader';
import Error from '../components/Error';
import CandidateGroup from '../components/CandidateGroup';
import Statement from '../components/Statement';
import { transformCandidate } from '../utils';
import { backendUrl } from '../config';
import statements from '../statements';

/*
 * Render Statement Title
 * ----------------------
 */

const rStatementTitle = (title) => (
  <p
    key={title}
    className="p-2 w-1/3 text-center text-sm font-bold tracking-wide text-gray-700"
  >
    {title}
  </p>
);

const rStatementTitles = statements.map(x => x.title).map(rStatementTitle);


/*
 * Render Candidate Group
 * ----------------------
 */

const rCandidateGroup = (candidates) => (
  <div>
    <div className="flex flex-row-reverse">
      <div
        className="hidden md:flex text-sm py-2 bg-gray-100 border border-b-0 rounded-t"
        style={{ width: '32rem' }}
      >
        {rStatementTitles}
      </div>
    </div>

    <CandidateGroup candidates={candidates.map(transformCandidate)} />
  </div>
);


/*
 * Render Statements
 * -----------------
 */

const rStatement = ({ title, text }) => (
  <div
    key={title}
    className="py-3"
  >
    <Statement
      title={title}
      text={text}
    />
  </div>
);

const rStatements = statements.map(rStatement);


/*
 * Page Component
 * --------------
 */

const RidingPage = ({ match: { params: { slug } } }) => {
  return (
    <>
      <div className="max-w-lg mx-auto px-4 py-8">
        <p>
          We have asked candidates if they agree with the following statements.
        </p>

        {rStatements}
      </div>

      <div className="bg-gray-200 px-4 py-16 mt-8">
        <div className="max-w-5xl mx-auto overflow-x-auto">
          <Loader
            url={`${backendUrl}/api/candidates/${slug}`}
            onSuccess={rCandidateGroup}
            onError={message => <Error message={message} />}
          />
        </div>
      </div>
    </>
  );
};

export default RidingPage;
