export default [
  {
    title: 'Climate Emergency',
    text: 'The world is in the middle of a climate emergency and the time to act is now.'
  },

  {
    title: 'Citizens’ Assembly',
    text: 'The federal government should establish a citizens’ assembly, a group of Canadians from all walks of life, to learn about Canada’s sustainability challenges, discuss, and develop solutions.'
  },

  {
    title: 'Approval',
    text: 'The assembly should be able to divide and send its proposed solutions to the federal and/or provincial parliaments, federal and/or provincial executives, or Canadian people (through a referendum) for approval, as it sees fit.'
  },
];
