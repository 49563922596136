import React from 'react';

const Option = ({ text, selected, onClick }) => (
  <button
    className={`p-3 block w-full h-full font-medium rounded text-gray-600 border ${selected ? 'border-gray-600 text-gray-800 bg-gray-100' : ''}`}
    onClick={onClick}
    type="button"
  >
    {text}
  </button>
);

export default Option;
