import React from 'react';

const SearchForm = ({ onSubmit, ...rest }) => (
  <form
    className="flex rounded border overflow-hidden"
    onSubmit={onSubmit}
  >
    <input
      className="flex-grow p-3 outline-none focus:outline-none"
      {...rest}
    />

    <div className="p-2">
      <button
        type="submit"
        className="bg-gray-200 text-gray-800 px-4 py-2 rounded text-sm font-bold tracking-wide"
      >
        Submit
      </button>
    </div>
  </form>
);

export default SearchForm;
