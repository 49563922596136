import React from 'react';

const Statement = ({ title, text }) => (
  <>
    <p className="text-sm font-bold tracking-wide text-gray-600">
      {title}
    </p>

    <p className="mt-1">
      {text}
    </p>
  </>
);

export default Statement;
