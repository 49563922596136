import React from 'react';
import Option from './Option';

const Options = ({ answer, setAnswer }) => {
  const options = [
    'Strongly agree',
    'Agree',
    'Slightly agree',
    'Neutral',
    'Slightly disagree',
    'Disagree',
    'Strongly disagree',
    'No opinion / not sure',
  ];

  const rOption = (option) => (
    <div
      key={option}
      className="w-1/3 md:w-auto md:flex-grow p-2"
    >
      <Option
        selected={answer === option}
        text={option}
        onClick={() => setAnswer(option)}
      />
    </div>
  );
  
  return (
    <div className="flex -mx-2">
      {options.map(rOption)}
    </div>
  );
};

export default Options;
