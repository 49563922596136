import React from 'react';

const AboutPage = () => (
  <div className="max-w-xl mx-auto px-4 py-8">
    <p>
      We are a group of concerned Canadians who believe that transformative change can only come about through the initiative and support of citizens.
    </p>

    <p className="mt-4">
      We cannot wait for politicians to act. We must give them a clear mandate through a national citizens’ assembly on climate action (a ‘climate assembly’).
    </p>

    <p className="mt-4">
      Climate assemblies are getting underway around the world. A climate assembly for Canada would put us at the leading edge of addressing this urgent issue. 
    </p>

    <p className="mt-4">
      Please contact your candidate (or, post-election, your MP) and urge them to pledge their support for a citizens’ assembly on climate action.
    </p>

    <p className="mt-4">
      If you have any questions, please email us at info@climateassembly.ca
    </p>

    <h3 className="mt-8 text-lg font-medium">
      Our Core Principles
    </h3>

    <ol className="list-decimal">
      <li className="my-1 ml-3"><span className="font-bold">Non-partisan</span> We have no stance on which candidate or party you should vote for or support. We are here to provide information to help you make your own decisions.</li>
      <li className="my-1 ml-3"><span className="font-bold">Transparent</span> We will be explicit about how we conduct our campaign. You may contact us at any time to inquire about any aspect of our campaign that is not clear from our website.</li>
      <li className="my-1 ml-3"><span className="font-bold">Non-commercial</span> We will never use any information that we collect from you for commercial purposes.</li>
      <li className="my-1 ml-3"><span className="font-bold">Privacy</span> Any information that you provide to us, such as a postal code or email address, will be kept in the strictest confidence and will not be shared with any third party without your explicit consent, unless required by law. You may contact us and instruct us to delete any records that we may have of any information that you provided to us, at any time.</li>
    </ol>

    <h3 className="mt-8 text-lg font-medium">
      Project Team
    </h3>

    <p className="mt-4">
      Norman M. Kearney is a PhD candidate at the University of Waterloo. His current research is on how societies evolve. He has led projects and done research on civic engagement, such as participatory budgeting in Hamilton and Kitchener, Ontario. He holds an MA in Political Economy from Carleton University and a BA in Philosophy & Political Science from McMaster University.
    </p>

    <p className="mt-4">
      Ciprian Mihalcea is a software developer and entrepreneur. He holds a BA in Economics from the University of Waterloo.
    </p>
  </div>
);

export default AboutPage;
