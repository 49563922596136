import React from 'react';
import { ExternalLink } from '../components/Link';

const NotFoundPage = () => (
  <div className="max-w-xl mx-auto px-4 py-8">
    <p>
      If you are a candidate and have not yet received a message containing a link to answer the questions, please message&nbsp;
      <ExternalLink
        href="https://twitter.com/canadaassembly"
      >
        @CanadaAssembly
      </ExternalLink>
      &nbsp;on Twitter and we will send you your access code.
    </p>
  </div>
);

export default NotFoundPage;
