import React from 'react';
import statements from '../statements';
import { ExternalLink } from '../components/Link';
import { imageUrl, defaultImageUrl } from '../config';

const Answer = ({
  statement,
  answer
}) => (
  <div className="flex md:p-2">
    <p className="md:hidden text-gray-700 pr-2">
      {statement}
    </p>

    <p className="md:text-center md:w-full">
      {answer}
    </p>
  </div>
);

const Answers = ({
  answers,
}) => (
  <>
    <div className="w-full md:w-1/3">
      <Answer
        statement={statements[0].title}
        answer={answers[0]}
      />
    </div>

    <div className="w-full md:w-1/3">
      <Answer
        statement={statements[1].title}
        answer={answers[1]}
      />
    </div>

    <div className="w-full md:w-1/3">
      <Answer
        statement={statements[2].title}
        answer={answers[2]}
      />
    </div>
  </>
);


const AskThem = ({
  twitter,
  tel,
  phoneNumber,
  email,
}) => (
  <div>
    <p>Not responded: Ask them to answer</p>
    {twitter && (
      <p>
        <ExternalLink
          href={`https://twitter.com/intent/tweet?text=Can%20Canadians%20count%20on%20you%20@${twitter}%20to%20support%20bold%20%23climate%20action?%20Please%20answer%20our%20survey,%20to%20help%20voters%20decide:%20https://climateassembly.ca/%20Citizens%20are%20also%20signing%20our%20petition.%20%23cdnpoli%20%23elxn43%20%23climateassembly`}
          target="_blank"
        >
          Tweet them!
        </ExternalLink>
      </p>
    )}

    {phoneNumber && (
      <p>
        <ExternalLink
          href={`tel:${tel}`}
          target="_blank"
        >
          Call {phoneNumber}
        </ExternalLink>
      </p>
    )}

    {email && (
      <p>
        <ExternalLink
          href={`mailto:${email}`}
          target="_blank"
        >
          {email}
        </ExternalLink>
      </p>
    )}
  </div>
);


const Candidate = ({
  displayName,
  partyNameEn,
  partyNameFr,
  image,
  answers,
  twitter,
  phoneNumber,
  email,
  facebook,
  instagram,
  tel,
}) => (
  <div className="flex">
    <div className="flex-grow flex items-center p-2">
      <div className="flex-shrink-0 p-2">
        <img
          src={image ? `${imageUrl}/${image}` : defaultImageUrl}
          alt="placeholder"
          className="rounded-full w-16 h-16 object-cover border"
        />
      </div>

      <div className="flex-shrink-0 w-64 p-2 flex-grow">
        <p className="text-gray-800">
          {displayName}
        </p>

        <p className="text-gray-600 text-sm">
          {partyNameEn} / {partyNameFr}
        </p>

        <div className="flex">
          {twitter && (
            <a
              href={`https://twitter.com/${twitter}`}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <i className="fab pr-2 text-gray-600 fa-twitter-square" />
            </a>
          )}

          {facebook && (
            <a
              href={facebook}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <i className="fab pr-2 text-gray-600 fa-facebook-square" />
            </a>
          )}

          {instagram && (
            <a
              href={`https://instagram.com/${instagram}`}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <i className="fab pr-2 text-gray-600 fa-instagram" />
            </a>
          )}
        </div>

        {answers ? <div className="md:hidden"><Answers answers={answers} /></div> : (
          <div className="md:hidden">
            <AskThem
              twitter={twitter}
              phoneNumber={phoneNumber}
              tel={tel}
              email={email}
            />
          </div>
        )}
      </div>
    </div>

    <div
      className="hidden md:block bg-gray-100 text-gray-800 flex-shrink-0 text-center border-l"
      style={{ width: '32rem' }}
    >
      <div className="h-full p-2 flex items-center">
        {answers ? <Answers answers={answers} /> : (
          <div className="w-full text-left">
            <AskThem
              twitter={twitter}
              phoneNumber={phoneNumber}
              tel={tel}
              email={email}
            />
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Candidate;
