import React from 'react';

const Error = ({ message }) => (
  <div className="p-3 rounded border border-l-4">
    <div className="text-md">
      <i className="pr-3 fas fa-exclamation-circle opacity-75" />
      {message}
    </div>
  </div>
);

export default Error;
