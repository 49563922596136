import React from 'react';
import { NavLink } from 'react-router-dom';

const Link = ({ className, activeClassName, ...props }) => (
  <NavLink
    className={`pb-1 border-b border-gray-300 hover:border-gray-500 text-sm font-bold tracking-wide text-gray-600 ${className}`}
    activeClassName={`border-gray-500 text-gray-900 ${activeClassName}`}
    {...props}
  />
);


export const PlainLink = ({ className, activeClassName, ...props }) => (
  <NavLink
    className={`pb-1 text-sm font-bold tracking-wide text-gray-600 ${className}`}
    activeClassName={`border-gray-500 text-gray-900 ${activeClassName}`}
    {...props}
  />
);


export const ExternalLink = ({ className, ...props }) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    className={`pb-1 border-b border-gray-300 hover:border-gray-500 text-sm font-bold tracking-wide text-gray-600 ${className}`}
    {...props}
  />
);

export default Link;
