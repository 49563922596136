import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import FaqPage from './pages/Faq';
import RidingPage from './pages/Riding';
import NotFoundPage from './pages/NotFound';
import ForCandidatePage from './pages/ForCandidate';
import AnswerPage1 from './pages/Answer1';
import AnswerPage2 from './pages/Answer2';
import { PlainLink } from './components/Link';
import flag from './assets/images/flag.png';

/*
 * Menu items
 * ----------
 */

const items = [
  { exact: true, to: '/', children: 'Home' },
  { exact: true, to: '/faq', children: 'FAQ' },
  { exact: true, to: '/about', children: 'About' },
];

const rItems = items => items.map((item) => (
  <div key={item.to} className="p-4">
    <PlainLink key={item.to} {...item} />
  </div>
));


/*
 * Routing pages
 * -------------
 */

const pages = [
  { exact: true, path: '/', component: HomePage },
  { exact: true, path: '/about', component: AboutPage },
  { exact: true, path: '/faq', component: FaqPage },
  { exact: true, path: '/riding/:slug', component: RidingPage },
  { exact: true, path: '/answer/:code', component: AnswerPage2 },
  { exact: true, path: '/answer', component: AnswerPage1 },
  { exact: true, path: '/for-candidate', component: ForCandidatePage },
  { exact: true, path: '*', component: NotFoundPage },
];

const rPages = pages => pages.map((page) => (
  <Route key={page.path} {...page} />
));


/*
 * Header Component
 * ----------------
 */

const Header = () => (
  <header className="pt-4">
    <a href="/">
      <img
        src={flag}
        alt="Flag"
        className="w-10 mx-auto"
      />

      <p className="text-center mt-2 font-medium tracking-wide">
        Climate Assembly Canada
      </p>
    </a>

    <div className="flex justify-center border-t border-b mt-4">
      {rItems(items)}
    </div>
  </header>
);

/*
 * Footer Component
 * ----------------
 */

const Footer = () => (
  <footer className="py-4">
    <p className="text-center text-gray-700">
      &copy; 2019, Climate Assembly Canada
    </p>
  </footer>
);


/*
 * App Component
 * -------------
 */

const App = () => {
  return (
    <div className="min-h-screen text-gray-900">
      <BrowserRouter>
        <Header />

        <Switch>
          {rPages(pages)}
        </Switch>

        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
