import React, { useState, useEffect } from 'react';
import Answer from '../components/Answer';
import statements from '../statements';
import Error from '../components/Error';
import { backendUrl } from '../config';


const AnswerPage = ({ match: { params: { code } } }) => {
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [candidate, setCandidate] = useState();

  const [answers, setAnswers] = useState([null, null, null]);
  const setAnswer = (answer, i) => setAnswers(x => {
    x[i] = answer;
    return [...x];
  });

  useEffect(() => {
    fetch(`${backendUrl}/api/candidate/${code}`)
      .then((response) => {
        if (response.ok) {
          response.json().then((x) => {
            setCandidate(x) 
            if (x.answers) {
              setAnswers(x.answers);
            };
          });
        } else {
          response.json().then(setError).catch(({ message }) => setError(message));
        }
      })
      .catch(({ message }) => setError(message))
  }, [code])

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${backendUrl}/api/candidate/${code}/answers`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ answers }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(setSuccess);
        } else {
          response.json().then(setError).catch(({ message }) => setError(message));
        }
      })
      .catch(({ message }) => setError(message))
  };

  const rAnswer = ({ text, title }, i) => (
    <div className="border-t py-12" key={title}>
      <Answer
        text={text}
        title={title}
        answer={answers[i]}
        setAnswer={(x) => setAnswer(x, i)}
      />
    </div>
  );

  return (
    <div className="py-8">
      <div className="max-w-xl mx-auto px-4">
        {error && (
          <div className="mb-4">
            <Error message={error}/>
          </div>
        )}

        {candidate && (
          <div>
            <p className="text-lg">{candidate.display_name}</p>
            <p className="text-gray-600 text-sm">{candidate.riding_name}</p>

            <p className="mt-4 text-gray-700">
              Thank you for visiting our website. For each of the statements below, please indicate your level of agreement or disagreement. You may also choose "no opinion/not sure".
            </p>

            <p className="mt-4 text-gray-700">
              We will post your answers on our website, to help voters decide. After the election, we will continue to collect and post answers from MPs.
            </p>
          </div>
        )}
      </div>

      {success ? (
        <div className=" max-w-xl mx-auto px-4 rounded border border-l-4 mt-4">
          <p className="p-3">{success}</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mt-4">
            {statements.map(rAnswer)}
          </div>

          <div className="flex justify-center">
            <button className="bg-gray-200 text-gray-800 px-6 py-3 rounded font-bold tracking-wide">
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AnswerPage;
