import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Link, { ExternalLink } from '../components/Link';
import SearchForm from '../components/SearchForm';
import Error from '../components/Error';
import { backendUrl } from '../config';


/*
 * Intro Component
 * ---------------
 */

const Intro = () => (
  <section className="text-gray-800">
    <p>
      It might not feel like it, but the world is in the middle of a <span className="font-bold">climate emergency</span>, and it's happening here in Canada. The way we’ve been living <span className="font-bold">just isn’t sustainable</span>.
    </p>

    <p className="mt-4">
      It’s time to rethink the basic things that we take for granted – things like how we produce energy, where we get our food, how we make things, what we do with waste, and how we travel.
    </p>

    <p className="mt-4">
      Big changes are necessary, but <span className="font-bold">we all have lives to live</span>. How can we make sure that big changes <span className="font-bold">make sense for all Canadians</span>?
    </p>

    <p className="mt-4">
      We propose a citizens’ assembly. The citizens’ assembly will bring together a group of Canadians from all walks of life, to study the issues and come up with solutions.
    </p>

    <p className="mt-4">
      We believe that this is the <span className="font-bold">only realistic way</span> of ensuring a <span className="font-bold">brighter future for all Canadians</span>.
    </p>

    <p className="mt-4">
      The federal government has the power to make this happen. Join us in calling on our representatives to establish a citizens’ assembly for climate action.
    </p>
  </section>
);


/*
 * Petition Component
 * ------------------
 */

const Petition = () => (
  <section>
    <h3 className="text-2xl font-medium">
      Support the petition on Change.org
    </h3>

    <div className="mt-3">
      <ExternalLink
        href="https://www.change.org/p/liberal-party-of-canada-a-citizens-assembly-and-a-green-new-deal-for-canada"
        target="_blank"
      >
        Petition
      </ExternalLink>
    </div>
  </section>
);


/*
 * Candidate Ask
 * -------------
 */

const CandidateAsk = () => (
  <section>
    <h3 className="text-2xl font-medium">
      Are you a Candidate?
    </h3>

    <div className="mt-3">
      <Link to="for-candidate">
        Click Here
      </Link>
    </div>
  </section>
);


/*
 * Search Component
 * ----------------
 */

const Search = ({
  value,
  onChange,
  onSubmit,
}) => (
  <section>
    <h3 className="text-2xl font-medium">
      Do my candidates support this initiative?
    </h3>

    <div className="mt-3">
      <p className="text-sm font-bold tracking-wide text-gray-600">
        Enter your Postal Code
      </p>

      <div className="mt-1">
        <SearchForm
          required
          value={value}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  </section>
);

/*
 * Page Component
 * --------------
 */

const HomePage = () => {
  const history = useHistory();

  const [postal, setPostal] = useState('');
  const [error, setError] = useState();

  const handlePostalChange = ({ target: { value } }) => {
    setPostal(value);
  };

  const handlePostalSubmit = (event) => {
    event.preventDefault();
    fetch(`${backendUrl}/api/postal/${postal}/riding`)
      .then((response) => {
        if (response.ok) {
          response.json().then(({ riding_slug }) => {
            history.push(`/riding/${riding_slug}`);
          });
        } else {
          response.json().then(setError).catch(({ message }) => setError(message));
        }
      })
      .catch(({ message }) => setError(message))
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <Intro />

      <div className="mt-8">
        <Petition />
      </div>

      <div className="mt-8">
        <Search
          value={postal}
          onChange={handlePostalChange}
          onSubmit={handlePostalSubmit}
        />

        {error && (
          <div className="mt-4">
            <Error message={error}/>
          </div>
        )}
      </div>

      <div className="mt-8">
        <CandidateAsk />
      </div>

    </div>
  );
};

export default HomePage;
