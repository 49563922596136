export const searchByName = (candidates, term) => candidates
  .filter(({ displayName, partyNameEn, partyNameFr }) => {
    const queryAgainst = `${displayName} ${partyNameEn} ${partyNameFr}`.toLowerCase();
    return term
      .toLowerCase()
      .split(/(\s+)/)
      .every(t => queryAgainst.includes(t))
  });


export const transformCandidate = ({
  display_name,
  first_name,
  last_name,
  party_name_en,
  party_name_fr,
  phone_number,
  riding_name,
  riding_slug,
  ...rest
}) => ({
  displayName: display_name,
  firstName: first_name,
  lastName: last_name,
  partyNameEn: party_name_en,
  partyNameFr: party_name_fr,
  phoneNumber: phone_number,
  ridingName: riding_name,
  ridingSlug: riding_slug,
  ...rest,
});
