import React from 'react';
import Statement from '../components/Statement';
import Options from '../components/Options';

const Answer = ({ text, title, answer, setAnswer }) => (
  <div className="py-4">
    <div className="px-4 max-w-xl mx-auto">
      <Statement
        text={text}
        title={title}
      />
    </div>

    <div className="px-4 max-w-5xl mx-auto mt-6">
      <Options
        answer={answer}
        setAnswer={setAnswer}
      />
    </div>
  </div>
);

export default Answer;
