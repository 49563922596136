import React from 'react';
import Candidate from './Candidate';

const CandidateGroup = ({ candidates }) => {
  const borderIfLast = index => index !== candidates.length - 1
    ? 'border-b'
    : null;

  const rCandidate = ({ slug, ...rest }, index) => (
    <div
      key={slug}
      className={borderIfLast(index)}
    >
      <Candidate {...rest} />
    </div>
  );

  return (
    <div className="bg-white border rounded rounded-tr-none overflow-hidden">
      {candidates.map(rCandidate)}
    </div>
  );
};

export default CandidateGroup;
