import React from 'react';

const FaqPage = () => (
  <div className="max-w-xl mx-auto px-4 py-8">
    <h3 className="text-lg font-medium">
      What is a citizens’ assembly?
    </h3>

    <p className="mt-3 text-gray-700">
      A citizens’ assembly is a group of citizens, randomly selected from a pool of volunteers, who come together to learn about a complex issue and develop solutions.
    </p>

    <h3 className="mt-8 text-lg font-medium">
      Has this been tried before?
    </h3>

    <p className="mt-3 text-gray-700">
      Yes! This year, the President of France setup a national climate assembly [4]. It will meet several times to learn about problems and solutions, discuss, and develop proposals. These assembly will decide which proposals will be voted on by parliament and which will be voted on by all French citizens in a national referendum. At the end of the process, the assembly will issue a report on how well the government respected the process.
    </p>

    <h3 className="mt-8 text-lg font-medium">
      Why are big changes necessary?
    </h3>

    <p className="mt-3 text-gray-700">
      Simply put, small changes haven’t worked.
    </p>

    <p className="mt-3 text-gray-700">
      At present, Canada is on track to achieve only four of the seventeen UN Sustainable Development Goals, which come due in 2030 [5]. Major challenges remain for Goal #12, Responsible Consumption and Production, and Goal #13, Climate Action.
    </p>

    <p className="mt-3 text-gray-700">
      Earlier this year, a leaked report revealed that Canada, on average, is warming at twice the global rate [6].
    </p>

    <p className="mt-3 text-gray-700">
      Crucially, data from the Community Index of Well-Being suggests that Canada is most likely failing to achieve any of the Sustainable Development Goals in most if not all Indigenous communities [7],[8],[9].
    </p>

    <p className="mt-3 text-gray-700">
      We need to rethink the basic things that we take for granted – things like how we produce energy, where we get our food, how we make things, what we do with waste, and how we travel.
    </p>

    <h3 className="mt-8 text-lg font-medium">
      Does anyone really care?
    </h3>

    <p className="mt-3 text-gray-700">
      Yes! Over the past year, thousands of Canadians gathered in town halls held across the country to envision a Green New Deal, a national plan for transforming Canada into a more sustainable and inclusive version of itself [10].
    </p>

    <p className="mt-3 text-gray-700">
      Polling suggests that Canadians are open to big changes. Earlier this year, Abacus Data found that 61% of Canadians supported or somewhat supported the idea of a Green New Deal [13].
    </p>

    <p className="mt-3 text-gray-700">
      In recent weeks, Canadians from coast to coast to coast turned out in the hundreds of thousands at climate strikes to demonstrate their concern about the environment and the future of our society [11].
    </p>

    <h3 className="mt-8 text-lg font-medium">
      Why a citizens’ assembly?
    </h3>

    <p className="mt-3 text-gray-700">
      Complex issues affect various individuals and groups in different ways. A citizens’ assembly is a way of ensuring that any proposals for addressing a complex issue reflect the values and perspectives of the individuals and groups who will be affected. It is a way of arriving at an outcome that is at least satisfactory to everyone involved, if not ideal. Citizens’ assemblies may help to develop proposals that are perceived as legitimate and that can achieve widespread public support.
    </p>

    <h3 className="mt-8 text-lg font-medium">
      How does a citizens’ assembly work?
    </h3>

    <p className="mt-3 text-gray-700">
      There is no one approach, but citizens’ assemblies often involve a series of meetings, held over weeks or months, to learn about a complex issue, discuss, and develop solutions. Citizens’ assemblies involve collaboration among citizens and representatives of government, academia, industry, and civil society.
    </p>

    <h3 className="mt-8 text-lg font-medium">
      Where have citizens’ assemblies been tried?
    </h3>

    <p className="mt-3 text-gray-700">
      The tradition dates back to ancient Greece, but recent examples include France’s national climate assembly (2019 - present) and citizens’ assemblies on electoral reform in Ontario (2004), British Columbia’s (2006), and the Netherlands (2006). Following the Global Climate Strike, citizens in Wellington, New Zealand have also started talking about a citizens’ assembly on climate change [12].
    </p>

    <h3 className="mt-8 text-lg font-medium">
      What were the outcomes of the citizens’ assemblies on electoral reform?
    </h3>

    <p className="mt-3 text-gray-700">
      In Ontario, a majority of Ontarians rejected their citizens’ assembly’s proposal in a referendum. A majority of British Columbians approved their citizens’ assembly’s proposal, but not a large enough majority (the referendum required 60% in favour, and 57.7% was achieved). In the Netherlands, no referendum was held, and the government rejected their citizens’ assembly’s proposal.
    </p>

    <h3 className="mt-8 text-lg font-medium">
      What do the outcomes of the recent citizens’ assemblies say about the prospects for this one?
    </h3>

    <p className="mt-3 text-gray-700">
      On the one hand, none of the examples from above led to changes being implemented. On the other hand, each example showed that people can come together, learn about a complex issue, discuss, and develop solutions. The key area for improvement seems to be getting the general public to trust and support the work done by their citizens’ assembly. For that, more efforts to communicate regularly with the public need to be made. The citizens’ assembly, while a small group (e.g., 100-250 people), needs to be a truly national process. It should be talked about in classrooms, at dinner tables, at the water cooler, on radio and television, and throughout social media. The whole country needs to pull together.
    </p>

    <h3 className="mt-8 text-lg font-medium">
      How would the citizens’ assembly’s proposals be implemented?
    </h3>

    <p className="mt-3 text-gray-700">
      Following the French model, the citizens’ assembly would be able to divide and send its proposed solutions to the federal and/or provincial parliaments, federal and/or provincial executives, or Canadian people (through a referendum) for approval, as it sees fit.
    </p>




    <ul className="mt-3 text-gray-700">
      <li>
        [4] https://www.conventioncitoyennepourleclimat.fr/
      </li>
      <li>
        [5] https://dashboards.sdgindex.org/#/CAN
      </li>
      <li>
        [6] https://www.cbc.ca/news/technology/canada-warming-at-twice-the-global-rate-leaked-report-finds-1.5079765
      </li>
      <li>
        [7] http://www.unesco.org/new/en/natural-sciences/priority-areas/links/related-information/what-is-local-and-indigenous-knowledge
      </li>
      <li>
        [8] http://www.oag-bvg.gc.ca/internet/English/parl_oag_201805_05_e_43037.html
      </li>
      <li>
        [9] https://alliance2030.ca/undrip-and-the-sdgs-theres-no-sustainable-future-without-indigenous-rights/
      </li>
      <li>
        [10] https://act.greennewdealcanada.ca/what-we-heard/
      </li>
      <li>
        [11] https://globalclimatestrike.net/
      </li>
      <li>
        [12] https://www.stuff.co.nz/environment/climate-news/116248519/plan-for-citizens-assembly-for-climate-actin-in-wellington-takes-shape
      </li>
      <li>
        [13] https://www.thestar.com/politics/federal/2019/04/17/majority-of-canadians-support-a-green-new-deal-poll-finds.html
      </li>
    </ul>
  </div>
);

export default FaqPage;
