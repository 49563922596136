import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchForm from '../components/SearchForm';
import Error from '../components/Error';
import { backendUrl } from '../config';


const AnswerPage = () => {
  const history = useHistory();

  const [code, setCode] = useState('');
  const [error, setError] = useState();

  const handleChange = ({ target: { value } } ) => {
    setCode(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${backendUrl}/api/candidate/${code}`)
      .then((response) => {
        if (response.ok) {
          response.json().then(() => {
            history.push(`/answer/${code}`);
          });
        } else {
          response.json().then(setError).catch(({ message }) => setError(message));
        }
      })
      .catch(({ message }) => setError(message))
  };

  return (
    <div className="max-w-xl mx-auto px-4 py-8">
      <SearchForm
        value={code}
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder="Enter Code"
      />

      {error && (
        <div className="mt-4">
          <Error message={error}/>
        </div>
      )}
    </div>
  );
};

export default AnswerPage;
