import React, { useState, useEffect } from 'react';

const Loader = ({ url, init, onSuccess, onError }) => {
  const [data, setData] = useState();
  const [error, setError] = useState();

  const handleSuccess = (result) => {
    setError();
    setData(result);
  };

  const handleFailure = (message) => {
    setError(message);
    setData();
  };

  const handleResponse = (response) => {
    if (response.ok) {
      response.json().then(handleSuccess)
    } else {
      response.json().then(x => handleFailure(x.data));
    }
  };

  const fetchData = () => {
    fetch(url, init)
      .then(handleResponse)
      .catch(x => handleFailure(x.message));
  };

  useEffect(fetchData, [url]);

  return (
    <div>
      {data ? onSuccess(data) : null}
      {error ? onError(error) : null}
    </div>
  );
};

export default Loader;
